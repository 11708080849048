import axios from "axios";

export interface IPostPatriHome {
  OI?: File;
  OD?: File;
  BIOWEL?: string;
}


export async function postPatriHome(params: IPostPatriHome): Promise<{
  der: number;
  izq: number;
}> {
    const formData = new FormData();

    if (params.OI) formData.append("OI", params.OI);
    if (params.OD) formData.append("OD", params.OD);
    if (params.BIOWEL) formData.append("BIOWEL", params.BIOWEL);

    const { data } = await axios.post(
        "https://cvpatrii.ttncompany.com/home/",
        formData
    );

    return data;
}

export async function getPatriProcess(payload: number | string): Promise<{
  ojoDer: number,
  ojoIzq: number
}> {

    const { data } = await axios.post(
        `https://cvpatrii.ttncompany.com/procesar/${payload}/`,
    );

    return data;
}

export async function postPatriDownloadPdf(payload:number | string): Promise<File> {
    const { data } = await axios.post(
        `https://cvpatrii.ttncompany.com/descargar/${payload}/`,
        "content=reporte.pdf",
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                "Accept": "application/pdf"
            },
            responseType: "blob",
        }
    );
    return data;
}