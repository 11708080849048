import { SectionCard, Button } from "@/components";
import { useState } from "react";
import { downloadPdfPatri, processFilePatri, sendFilePatriHome } from "../patri.actons";
import { useAppDispatch } from "@/hooks";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IconRefresh } from "@tabler/icons-react";

const IAInterpretationSection = ({ OI, OD }: { OI?: File, OD?: File }) => {
    const dispatch = useAppDispatch();
    const [showReportDetail, setShowReportDetail] = useState(false);
    const [message, setMessage] = useState("");
    const [pdf, setPdf] = useState("");
    const [reprocess, setReprocess] = useState(false);

    const postHomePatri = async () => {
        setShowReportDetail(true);
        setMessage("");
        setPdf("");
        setReprocess(false);
        const data = await dispatch(sendFilePatriHome({ OD, OI, BIOWEL: "1" }, undefined, ({ message }) => setMessage(message)));

        if (data?.izq && data?.der) {
            const processIzq = await dispatch(processFilePatri(data.izq, undefined, ({ message }) => {
                setMessage(message);
                setReprocess(true);
            }));

            const processDer = await dispatch(processFilePatri(data.der,undefined, ({ message }) => {
                setMessage(message);
                setReprocess(true);
            }));

            if (processDer && processIzq) {
                const download = await dispatch(
                    downloadPdfPatri(data.der, ()=> setReprocess(true), ({ message }) => {
                        setMessage(message);
                        setReprocess(true);
                    })
                );
                if (download) {
                    setPdf(download);
                }
            }
            return;
        } else if (!data?.izq && !data?.der) {
            setPdf("");
            setMessage("Debe cargar las imágenes del ojo derecho o izquierdo");
            setReprocess(true);
        }

        if (data?.izq) {
            const processIzq = await dispatch(
                processFilePatri(data.izq,undefined, ({message})=> setMessage(message))
            );
            if (processIzq) {
                const download = await dispatch(
                    downloadPdfPatri(
                        data.izq,
                        ()=>setReprocess(true),
                        ({ message }) => {
                            setMessage(message);
                            setReprocess(true);
                        })
                );
                if (download) {
                    setPdf(download);
                }
            }
            return;
        }
        if (data?.der) {
            const processDer = await dispatch(
                processFilePatri(data.der, undefined, ({ message }) => {
                    setMessage(message);
                    setReprocess(true);
                })
            );
            if (processDer) {
                const download = await dispatch(
                    downloadPdfPatri(
                        data.der,
                        ()=>setReprocess(true),
                        ({ message }) => {
                            setMessage(message);
                            setReprocess(true);
                        })
                );
                if (download) {
                    setPdf(download);
                }
            }
            return;
        }
    };

    return (
        <SectionCard className="" cardAidStyle>
            {!showReportDetail
                ?
                <div className="d-flex align-items-center justify-content-center">
                    <Button
                        variant="outline"
                        size="large"
                        className="my-5 mx-5 h-fit"
                        onClick={() => postHomePatri() }
                    >
                      Realizar Interpretación por Inteligencia Artificial
                    </Button>

                </div>
                :
                <>
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-secondary">Interpretación IA</h5>
                        {reprocess && <div onClick={() => postHomePatri()} className=" pointer align-self-center">
                            <ReactTooltip
                                anchorId="replace"
                                place="bottom"
                                content="Volver a procesar"
                                style={{ width: "100px", textAlign: "center", opacity: "0.8", zIndex: 50 }}
                            />
                            <IconRefresh className="text-primary" id="replace"
                            />
                        </div>}

                    </div>
                    <hr className="lineCards" />
                    <div className="d-flex flex-column align-items-center justify-content-center my-5">
                        {!!pdf &&  <iframe
                            title="document"
                            src={pdf}
                            style={{ width: "100%", height: "500px"  }}
                            frameBorder="0"
                            className="pdfRender pb-4"
                        ></iframe>}
                        {
                            message ?
                                message
                                : !pdf ?<div className="loading-text">Interpretación en curso
                                    <div className="dots"></div>
                                </div> :
                                    ""
                        }
                        {
                            message && reprocess ?
                                (<h5 className="fw-bold text-secondary reprocess" onClick={()=> postHomePatri()}>Volver a procesar</h5>)
                                : ""
                        }
                    </div>
                </>
            }
        </SectionCard>
    );
};

export default IAInterpretationSection;
