import { AppDispatch } from "@/config/store";
import { getPatriProcess, IPostPatriHome, postPatriDownloadPdf, postPatriHome } from "@/services/patrii";

export function sendFilePatriHome(params: IPostPatriHome, doAfterSuccess?: () => void, doAfterError?: ({ message }: { message: string }) => void) {
    return async function (dispatch: AppDispatch) {
        try {
            const response = await postPatriHome(params);
            if (response.der || response.izq) {
                doAfterSuccess && doAfterSuccess();
                return response;
            }
        } catch {
            doAfterError && doAfterError({ message: "No se pudieron procesar los documentos" });
            return null;
        }
    };
}

export function processFilePatri(params: number | string, doAfterSuccess?: () => void, doAfterError?: ({ message }: { message: string }) => void) {
    return async function (dispatch: AppDispatch) {
        try {
            const response = await getPatriProcess(params);
            if (response.ojoIzq || response.ojoIzq) {
                doAfterSuccess && doAfterSuccess();
                return response;
            }
        }  catch {
            doAfterError && doAfterError({ message: "No se pudieron procesar los documentos" });
            return null;
        }
    };
}

export function downloadPdfPatri(params: number | string, doAfterSuccess?: () => void, doAfterError?: ({ message }: { message:string }) => void) {
    return async function (dispatch: AppDispatch) {
        try {
            const response = await postPatriDownloadPdf(params);
            if (response) {
                const pdfBlob = new Blob([response], { type: "application/pdf" });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                doAfterSuccess && doAfterSuccess();
                return pdfUrl;
            }
        } catch {
            doAfterError && doAfterError({ message: "No fue posible cargar el resultado del análisis" });
            return null;
        }
    };
}